<template>
  <div>
      <b-row>
        <b-col cols="12" md="3">
          
        </b-col>
        <b-col cols="6" md="3">
          <div class="logosSuperiores">
            <b-form-file v-model="archivo" ref="file-input" class="subirExcel" accept="application/vnd.ms-excel, vnd.sealed.xls, text/csv" @change="onLoad"></b-form-file>
            <h5>Seleccionar</h5>
            <span>{{ archivo ? archivo.name : '' }}</span>
          </div>
        </b-col>
        <b-col cols="6" md="3">
          <div class="logosSuperiores">
            <button class="imagenSubir" :disabled="deshabilitarSubir" @click="modalConfirmarSubida"></button>
             <h5>Subir</h5>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          
        </b-col>
      </b-row>
      <br /> <br />
      <b-row>
        <b-col>
          <b-table striped responsive small bordered :items="dataCompleta" :tbody-tr-class="rowClass"></b-table>
        </b-col>
      </b-row>
      
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import XLSX from 'xlsx'
import axios from 'axios';
import {RCDGETLICENCIABYNUMACTO, RCDCREATELICENCIA} from '../constantes';

export default {
  name: "SubirArchivos",
  data(){
    return { 
      archivo: [],
      tableData:[],
      tableRow: [],
      dataCompleta: [],
      interval: null,
      deshabilitarSubir:true
    }
  },
  mounted(){
    
  },
  computed:{
  },
  methods: {
    ...mapActions(['activarLoading']),
    explorarArchivo(f){
      this.dataCompleta = [];
      this.tableRow = [];
      this.tableData = [];
      var reader = new FileReader(),
          vm = this;
      reader.onload =  (e) =>{
        var data = e.target.result,
            workbook = XLSX.read(data, { type: 'binary' }),
            sheetName = workbook.SheetNames[0],
            sheet = workbook.Sheets[sheetName];
        for (var row = 1; ; row++) {
          if (sheet['A' + row] == null) {
            break;
          }
          
          for (var col = 65; col <= 90; col++) {
            var c = String.fromCharCode(col);// get 'A', 'B', 'C' ...
            var key = '' + c + row;
            if (sheet[key] == null) {
              break;
            }
            vm.tableRow.push(sheet[key]['w']);
          }
          vm.tableData.push(vm.tableRow);
          vm.tableRow = []
        }
        
        this.activarLoading(false)
        console.log('rows', vm.tableData)
        this.crearDataCompleta(vm.tableData)
      };
      reader.readAsBinaryString(f);
    },

    onLoad(){
      this.activarLoading(true)
      this.interval = setInterval(this.verificarArchivo, 500)
    },

    verificarArchivo(){
      if(this.archivo!=null){
        this.explorarArchivo(this.archivo);
        clearInterval(this.interval)
      }
    },

    async crearDataCompleta(array){
      for(let i= 1; i<array.length; i++){
          let items = array[i]
            let linea = {
              Registro: null
            }
          for(let n= 0; n<array[0].length; n++){
              let encabezado = array[0][n];
              linea[encabezado]=items[n]
          }
            let existe = await this.verificarCertificados(linea.NoActo)
            linea.Registro= existe
            this.dataCompleta.push(linea)
            if(i==array.length-1){
              this.deshabilitarSubir = false;
            }
        }
    },
    
    rowClass(item, type) {
      if (!item || type !== 'row') return
      return 'rowspace'
    },

    verificarCertificados(noActo){
      return axios.put(RCDGETLICENCIABYNUMACTO + noActo)
        .then(result=>{
            if(result.data.data.length >0){
                return 'Existente'
            }else{
                return 'Nuevo'
            }
        })
        .catch(err=>{console.log(err)})
    },

    async guardarCertificado(){
      this.activarLoading(true)
      for(let i=0; i<this.dataCompleta.length; i++){

        let item= {
           licencia: {
                num_acto:this.dataCompleta[i].NoActo,
                fecha_resolucion:this.dataCompleta[i].FResuelto,
                direccion:this.dataCompleta[i].Direcciones,
                cedula_catastral:this.dataCompleta[i].CedCatastrales,
                urbanizacion:this.dataCompleta[i].Urbaniza,
                titulares:this.dataCompleta[i].Titulares,
                descripcion_tramite:this.dataCompleta[i]['Descripción Trámite'],
                modalidad:this.dataCompleta[i].Modal,
                estrato:this.dataCompleta[i].Estrato,
                uso:this.dataCompleta[i].uso,
                intervencion:this.dataCompleta[i].Intv,
                fecha_registro: new Date(),
                estado: 0,
                cant_autorizadas: 0,
                cant_dispuestas: 0
              }
        }
        axios.post(RCDCREATELICENCIA, item)
        .then(()=>{
          if(i==this.dataCompleta.length-1){
            this.activarLoading(false)
            this.modalOk()
            this.borrarData()
          }
        })
        .catch(error=>console.log(error))
      }
    },

    modalConfirmarSubida(){
        this.$bvModal.msgBoxConfirm('Se van a cargar las licencias del archivo al sistema, ¿Desea continuar?.', {
          title: 'Por favor confirme',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continuar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value){
              this.guardarCertificado()
            }
          })
          .catch(err => {
            console.log(err)
          })
    },

    modalOk(){
      this.$bvModal.msgBoxOk('Los Datos fueron subidos correctamente.', {
          title: 'Confirmación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            console.log(value)
          })
          .catch(err => {
            console.log(err)
          })
    },

    borrarData(){
        this.archivo =  []
        this.tableData = []
        this.tableRow =  []
        this.dataCompleta =  []
        this.deshabilitarSubir = true
    }
    
  }
}
</script>

<style scoped>

  .logosSuperiores{
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #555;
    text-align: center;
  }

  .logosSuperiores .imagenExaminar {
    background: url('../assets/iconosApp.png');
    background-position: -13px -9px;
    height: 66px;
    width: 48px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .logosSuperiores button:disabled{
    opacity: 0.5;
  }
  
  .logosSuperiores .imagenSubir {
    background: url('../assets/iconosApp.png');
    background-position: -71px -10px;
    border: none;
    margin: 34px 0;
    height: 70px;
    width: 102px;
    position: relative;
  }
  
</style>